<script lang="ts" setup>
const document = ref();

usePageNavigation(document, "document");
</script>

<template>
  <ScrollWrapper>
    <Navigation />
    <section
      id="document"
      ref="document"
      :class="
        cn(
          'max-xl:w-[100vw] max-xl:shrink-0 xl:basis-0 xl:grow h-full flex flex-col',
          'max-xl:snap-start',
          '@container'
        )
      "
    >
      <NuxtPage />
    </section>
  </ScrollWrapper>
</template>
